import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
// import store from './store'
import VueElementLoading from 'vue-element-loading'
import SnackBar from './components/Common/snackBar'
import ServerError from './components/Common/500'
import ImageLoader from './components/Common/imageLoader'
import PageLoader from './components/Common/pageLoader'

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-5P5KFK1DKL" }
});

import VueFacebookPixel from 'vue-facebook-pixel'
Vue.use(VueFacebookPixel)


/**
 * Init facebook tracking pixel
 * @param  {String} appId 
 * @param  {object} [data={}] 
 */
 Vue.analytics.fbq.init('1473976132948732', {
//  em: 'jubinmj5@mail.com'
})
/**
 * Event tracking
 * @param  {String} name 
 * @param  {object} [data={}] 
 */
 Vue.analytics.fbq.event('ViewContent', {
  content_name: 'Donations'
})
import OwlCarousel from 'vue-owl-carousel'
Vue.component('OwlCarousel', OwlCarousel)
Vue.component('ServerError', ServerError)
Vue.component('SnackBar', SnackBar)
Vue.component('ImageLoader', ImageLoader)
Vue.component('PageLoader', PageLoader)
Vue.component('VueElementLoading', VueElementLoading)

Vue.config.productionTip = false
window.axios = require('axios')

axios.defaults.baseURL = 'https://wtiadmin.in';
Vue.prototype.baseURL = "https://wtiadmin.in";
Vue.prototype.mediaURL = "https://wtiadmin.in/u/"


// axios.defaults.baseURL = 'http://192.168.50.114:5000';
// Vue.prototype.baseURL = 'http://192.168.50.114:5000'
// Vue.prototype.mediaURL = "http://192.168.50.114:5000/u/"

new Vue({
  router,
  // store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
